module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"jmmcduffie.com","short_name":"jmmcduffie.com","start_url":"/","background_color":"#fff","theme_color":"#48bb78","display":"standalone","icon":"src/images/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-webmention/gatsby-browser.js'),
      options: {"plugins":[],"username":"www.jmmcduffie.com","mentions":true,"pingbacks":false,"domain":"jmmcduffie.com","token":"m8zQaAh_0gQQH4Rf6SOLHg"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
